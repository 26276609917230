import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './PortfolioCarousel.css';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const responsive = {
	desktop: {
		breakpoint: {max: 3000, min: 1024},
		items: 4,
	},
	tablet: {
		breakpoint: {max: 1024, min: 768},
		items: 3,
	},
	mobile: {
		breakpoint: {max: 768, min: 430},
		items: 2,
	},
	small: {
		breakpoint: {max: 430, min: 0},
		items: 1,
	},
};

export default function PortfolioCarousel() {
	const {allPortfolio: {nodes: portfolio}} = useStaticQuery(graphql`
		query {
		  allPortfolio(
			filter: {published: {eq: true}, active: {eq: true}}
			sort: {fields: created, order: DESC}
		  ) {
			nodes {
			  title
			  link
			  image {
				localFile {
				  childImageSharp {
					gatsbyImageData(
					  height: 322
					  width: 430
					  formats: [AUTO, WEBP]
					)
				  }
				}
			  }
			}
		  }
		}
    `);

	return (<div style={{position: 'relative', textAlign:'center'}}>
		<Carousel
			// ssr
			// partialVisbile
			responsive={responsive}
			autoPlay={true}
			autoPlaySpeed={3000}
			// centerMode={true}
			renderDotsOutside
			swipeable
			// showDots
			arrows={false}
			infinite

		>
			{portfolio.map((item, i) => {
				let dragging = false;
				return <div
					className={'eelco'}
					key={i}
					style={{userSelect: 'none', width: '100%', marginBottom: 30}}
					onMouseDown={() => dragging = false}
					onMouseMove={() => dragging = true}
					onClick={event => dragging && event.preventDefault()}
				>
					<a href={item.link} target="_blank" draggable={false}>
						<GatsbyImage
							image={getImage(item.image.localFile)}
							alt={item.title}
							draggable={false}
						/>
						<h3>{item.title}</h3>
					</a>
				</div>;
			})}
		</Carousel>
	</div>);
}
